<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Create Accounts</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">

          <div class="col-md-4">

            <fg-input type="text"
                      label="Name"
                      placeholder="user name"
                      v-model="user.username" @input="upadte_change">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="email"
                      label="Email"
                      placeholder="Email"
                      v-model="user.email">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="Email"
                      label="Contact Number"
                      placeholder="Contact number"
                      v-model="user.email">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="country">select country</label>
            <select class="form-control" label="Contact Number" placeholder="d">
            <option v-for="country in user.country" :key="country.name"  :value="country.value">{{ country.name }}</option>
        </select>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="user.lastName">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Address"
                      placeholder="Home Address"
                      v-model="user.address">
            </fg-input>
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile">
            Update Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
  export default {
    data () {
      return {
        user: {

          name: '',
          email: '',
          conct_n: '',
          country:[
                    { name: "Inida", value: 'IN' },
                    { name: "Saudi Arabia", value: 'SA' },
                    { name: "UAE", value: 'AE' },
                ]

        }
      }
    },
    methods: {
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },
      upadte_change()
    {

      console.log( this.user.lastName);
      this.user.lastName=this.user.username;
    }
    },
    mounted () {
    console.log('ok')
    axios
      .get('https://restcountries.com/v3.1/all')
      .then(
        response => (
          this.info = response,
          console.log(this.info)
          ),
       // console.log(this.info)
        )

  }


  }

</script>
<style>

</style>
